<template>
  <Layout>
    <template v-slot:sidebar>
      <SidebarMain />
    </template>
    <template v-slot:content>
      <Template />
    </template>
  </Layout>
</template>

<script>
import Layout from '@/components/UI/Layout';
import SidebarMain from '@/viewsNew/SidebarMain';
import Template from '@/components/monitoring-settings/Template';

export default {
  components: {
    Layout,
    SidebarMain,
    Template
  }
};
</script>
