<template>
  <li
    @mouseenter="hoverProfile = true"
    @mouseleave="hoverProfile = false"
    @click="checkProfile"
    class="d-flex align-center jc-space-between bg-form px-16 py-8"
    :class="{
      'b-top bc-form-border': index !== 0,
      'cursor-pointer bg-form-darker_hover bg-form_active': !isDisabled
    }"
  >
    <div class="d-flex align-center">
      <div class="w-20px mr-5">
        <font-awesome-icon
          :icon="['fab', socialNetIcon]"
          color="#60BCE3"
        />
      </div>
      <div class="mr-8">
        <Avatar
          class="w-32px h-32px br-circle overflow-hidden"
          :src="avatar"
        />
      </div>
      <div class="d-flex align-center mr-5">
        <Icon name="brick-icon-done" />
        <!-- <span
          v-show="hoverProfile"
          @click.stop="profileDetails"
          class="color-link color-link-lighter_hover cursor-pointer uppercase fs-11 fw-600 mx-5"
        >
          Profile Details
        </span> -->
      </div>
      <div class="d-flex align-center fs-14">
        <div class="d-flex align-center mr-12">
          <Icon name="brick-icon" />
          <!-- <span
            v-show="hoverProfile"
            class="uppercase fs-11 fw-600 ml-5"
          >Social graph</span> -->
        </div>
        <span
          v-if="name"
          class="mr-8"
        >{{ name }}</span>
        <span
          v-if="location"
          class="color-text-lighter mr-8"
        >{{ location }}</span>
      </div>
    </div>
    <div class="d-flex align-center">
      <span
        v-if="profile.tags.length"
        class="fs-14 color-text-lighter mr-12"
      >Saved in tags</span>
      <template v-if="isDisabled">
        <span class="fs-14 color-text-lighter mr-12">Already monitored</span>
      </template>
      <template v-else>
        <input
          :id="'monitoring-profile-checkbox-' + profile.oid"
          type="checkbox"
          class="profile-card-checkbox pointer"
          :checked="checked"
          @change.prevent="checkProfile"
        >
        <label :for="'monitoring-profile-checkbox-' + profile.oid" class="profile-card-checkbox__label pointer"></label>
      </template>
    </div>
  </li>
</template>

<script>
import Icon from '@/components/app/Icon';
import Avatar from '@/components/app/Avatar';
import { getEntityName, getLocation, getEntityAvatar, getEntitySocialNetIcon } from '@/utils/helpers';
import { mapGetters } from 'vuex';

export default {
  name: 'MonitoringSettingsCaseEntity',
  props: ['caseName', 'caseId', 'profile', 'index', 'check', 'fromParams'],
  inject: ['addProfileForMonitoring', 'removeProfileFromMonitoring'],
  components: {
    Icon,
    Avatar
  },
  data () {
    return {
      checked: this.check,
      hoverProfile: false
    };
  },
  created () {
    if (this.isDisabled && this.fromParams.pid) {
      const action = (this.checked) ? this.addProfileForMonitoring : this.removeProfileFromMonitoring;
      action(this.profile);
    }
  },
  computed: {
    ...mapGetters(['monitoredProfiles']),
    name () {
      return getEntityName(this.profile);
    },
    location () {
      return getLocation(this.profile);
    },
    avatar () {
      return getEntityAvatar(this.profile) || require('@/assets/icons/avatar.png');
    },
    socialNetIcon () {
      return getEntitySocialNetIcon(this.profile);
    },
    isDisabled () {
      let bool = false;
      if (this.monitoredProfiles[this.caseId]) {
        const result = this.monitoredProfiles[this.caseId].find(p => p.oid === this.profile.oid);
        if (result !== undefined) bool = true;
      }
      return bool;
    }
  },
  methods: {
    checkProfile () {
      if (!this.isDisabled) {
        this.checked = !this.checked;
        const action = (this.checked) ? this.addProfileForMonitoring : this.removeProfileFromMonitoring;
        action(this.profile);
      }
    }
  }
};
</script>
