<template>
  <div class="d-flex align-center jc-space-between bg-form lh-48 b-bottom bc-form-border px-16">
    <div class="fs-14">{{ investigation.tag }}</div>
    <div class="d-flex align-center color-link cursor-pointer">
      <span
        @click="removeSelectedCase"
        class="fs-13 fw-600 mr-10"
      >
        cancel
      </span>
      <Icon
        name="arrow-down"
        class="rotate--180"
      />
    </div>
  </div>
  <div
    v-if="countProfiles || countProfilesTag"
    class="d-flex align-center bg-form fs-14 lh-48 b-bottom bc-form-border px-16 py-6"
  >
    <button
      v-if="countProfiles"
      id="filterMonitoringProfiles"
      class="fs-14 fw-600 h-36px br-5 px-16 mr-12"
      :class="{
        'bg-form-darker color-link' : filterProfiles !== '',
        'bg-link color-white' : filterProfiles === ''
      }"
      @click="filterProfiles = ''"
    >
      Profile Search results ({{ countProfiles }})
    </button>
    <button
      v-if="countProfilesTag && (countProfilesTag !== countProfiles)"
      id="filterMonitoringProfilesTags"
      class="fs-14 fw-600 h-36px br-5 px-16 mr-12"
      :class="{
        'bg-form-darker color-link' : filterProfiles !== 'tag',
        'bg-link color-white' : filterProfiles === 'tag'
      }"
      @click="filterProfiles = 'tag'"
    >
      Saved in tag ({{ countProfilesTag }})
    </button>
  </div>
  <ul
    v-if="showCaseEntity"
    class="profiles-list">
    <MonitoringSettingsCaseEntity
      v-for="(profile, index) in filteredProfiles"
      :caseName="investigation.tag"
      :caseId="investigation.oid"
      :profile="profile"
      :index="index"
      :key="profile.oid"
      :check="taskEntityIDs.includes(profile.oid)"
      :fromParams="fromParams"
    />
  </ul>
  <template v-else>
    <div class="monitoring-preloader p-relative bg-form text-center">
      <div
        v-show="loadProfiles"
        class="p-absolute abs-center"
      >
        <img
          src="@/assets/icons/searching.gif"
          class="h-50px"
        >
        <div class="text-center fs-16 lg-24 pl-12 mt-4">Loading...</div>
      </div>
      <div
        v-if="!loadProfiles && hideEmptyInfo"
        class="p-absolute abs-center-y fs-14 w-100"
      >
        Sorry, but you have no cases with profiles suitable for monitoring.
      </div>
    </div>
  </template>
</template>

<script>
import Icon from '@/components/app/Icon';
import MonitoringSettingsCaseEntity from '@/components/monitoring-settings/CaseEntity';

export default {
  name: 'MonitoringSettingsCase',
  props: ['investigation', 'profiles', 'fromParams', 'loadProfiles'],
  emits: ['removeSelectedCase'],
  components: {
    Icon,
    MonitoringSettingsCaseEntity
  },
  data () {
    return {
      filterProfiles: '',
      taskEntityIDs: []
    };
  },
  async created () {
    if (this.fromParams) {
      const caseID = this.investigation.oid;
      await this.$store.dispatch('getMonitoringTasks', caseID);
      const tasks = this.$store.getters.monitoringTasks[caseID] || [];
      this.taskEntityIDs = tasks.map(task => task.entity_id);
    }
  },
  computed: {
    filteredProfiles () {
      let profiles = this.profiles;
      if (this.investigation.pid) profiles = profiles.filter(p => p.oid === this.investigation.pid);
      if (this.filterProfiles === 'tag') {
        return profiles.filter(p => p.tags.length);
      } else {
        return profiles;
      }
    },
    countProfilesTag () {
      return this.filteredProfiles.filter(p => p.tags.length).length;
    },
    countProfiles () {
      let countProfiles = this.profiles.length;
      if (this.investigation.pid) countProfiles = 1;
      return countProfiles;
    },
    showCaseEntity () {
      if (this.fromParams && this.fromParams.pid) return (this.filteredProfiles.length && this.taskEntityIDs.length);
      else return this.filteredProfiles.length;
    },
    hideEmptyInfo () {
      if (this.fromParams && this.fromParams.pid) return false;
      else return true;
    }
  },
  methods: {
    removeSelectedCase () {
      this.$emit('removeSelectedCase');
    }
  }
};
</script>

<style lang="scss" scoped>
  .monitoring-preloader {
    height: 342px;
  }
</style>
